export const playlist = 
[
  {
    "id": 1,
    "title": "Gouyad",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 2,
    "title": "Chill Vibes",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 3,
    "title": "Annou danse",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 4,
    "title": "The Luckiest",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 5,
    "title": "Mizik se lavi",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 6,
    "title": "Unity",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  },
  {
    "id": 3,
    "title": "Annou danse",
    "backgroundImageUrl": "https://example.com/playlist2.jpg",
    "type": "playlist",
    "description": "Relax and unwind with these calming tunes",
    "createdBy": "Jane Smith",
    "releaseDate": "2022-02-15",
    "songs": [
      {
        "title": "Relaxing Song",
        "duration": "4:45",
        "id":1,
        "name": "First Snow",
        "artist": "Emancipator",
        "album": "Soon It Will Be Cold Enough",
        "url": "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg"
      },
      {
        "title": "Soothing Melody",
        "duration": "3:30",
        "id":2,
        "name": "Intro / Sweet Glory",
        "artist": "Jimkata",
        "album": "Die Digital",
        "url": "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
        "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg"
      },
      {
        "title": "Zen Vibes",
        "duration": "5:15",
        "name": "Offcut #6",
      "artist": "Little People",
      "album": "We Are But Hunks of Wood Remixes",
      "url": "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
      "cover_art_url": "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg"
      }
    ]
  }
]
