import { ChevronDownIcon, PlayCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Songs } from "../songs/songs";
import { playlist } from "../../utils/playlist";
import { album } from "../../utils/album";
import { c3 } from "../../assets";
import useScreenSize from "../../utils/screenSizeUtils";

function Playlist({ title, backgroundImageUrl }) {
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize === "sm" || screenSize === "xs";
  const backgroundImag = `url(${backgroundImageUrl})`;
  const backgroundImage = `url(${c3})`;
  const { type, id } = useParams();
  // Convert the id parameter to a number
  const playlistId = parseInt(id, 10);
  console.log("id check", id);
  let playlistData = [];

  // Determine the playlist data based on the type
  if (type === "playlist") {
    playlistData = playlist.find((playlist) => playlist.id === playlistId);
  } else if (type === "album") {
    playlistData = album.find((album) => album.id === playlistId);
  }

  if (!playlistData) {
    // Handle case when the playlist/album is not found
    return <div>Playlist or Album not found</div>;
  }

  // Assuming your playlistData structure includes a 'songs' field for songs in a playlist
  const playlistSongs = playlistData.songs || [];

  return (
    <section className="flex-grow h-screen w-full">
      <div
        className={`flex gap-4  h-[45vh] text-white bg-cover ${
          isSmallScreen ? "" : "bg-[#24303F]"
        } p-2 rounded-xl`}
        style={{
          backgroundImage: isSmallScreen ? backgroundImage : "",
        }}
      >
        <div
          className="hidden md:block h-full w-[40%] bg-slate-200 rounded-xl flex-shrink-0"
          style={{ backgroundImage }}
        ></div>
        <div className="flex md:flex-col justify-between md:justify-start items-end md:items-start w-full h-full">
          <div className={`flex flex-col gap-2`}>
            <p className={`hidden md:block text-sm`}>
              {type === "album" ? "ALBUM" : "PUBLIC PLAYLIST"}
            </p>
            <h1 className="text-4xl md:text-7xl xl:text-8xl font-bold text-white">
              {playlistData.title}
            </h1>
            <p className={`text-sm`}>{isSmallScreen ? "Outis" : "Outis . 2024 . 15"}</p>
          </div>
          <div className={`mt-4`}>
            <PlayCircle size={70}/>
          </div>
        </div>
      </div>
      <div className={`md:hidden flex justify-between p-2 rounded-xl w-full`}>
        <p className={`text-sm`}>  {type === "album" ? "Album" : "Playlist"} . 2024 </p>
        <p className={`text-sm`}> 3 songs . 2h</p>
      </div>
      <div className="mt-4 w-full">
        <Songs songs={playlistData.songs} name={playlistData.title} />
      </div>
    </section>
  );
}

export default Playlist;
