import React, { useEffect, useState } from "react";
import { Link, matchPath, Outlet, useLocation } from "react-router-dom";

import {
  LifeBuoy,
  Receipt,
  Boxes,
  Package,
  UserCircle,
  BarChart3,
  LayoutDashboard,
  Settings,
  Home,
  ListMusic,
} from "lucide-react";
import Footer from "../footer/footer";
import Sidebar, { SidebarItem } from "../sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import { selectThemeMode, toggleMode } from "../../features/theme/themeSlice";

import Header from "../header/header";
import Player from "../player/Player";
import {
  selectIsAuthenticated,
  selectLoading,
  checkAuthentication,
} from "../../features/user/userSlice";

const Layout = () => {
  const theme = useSelector(selectThemeMode);
  const [scrolled, setScrolled] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = useSelector(selectLoading);

  const location = useLocation();
  const dynamicRoutePattern = '/:type/:id';
  const hideHeaderOnPaths = ['/konekte', '/enskri',':/type/:id'];
 const showHeader = 
    !hideHeaderOnPaths.includes(location.pathname) && 
    !matchPath(dynamicRoutePattern, location.pathname);
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const dispatch = useDispatch();
  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    if (isScrolled !== scrolled) {
      setScrolled(isScrolled);
    }
  };

  console.log("Scroll Y:", window.scrollY);


  const handleClick = () => {
    setSidebarOpen((s)=>false)
  };

  // // Load the active item from localStorage on component mount
  // useEffect(() => {
  //   const storedActiveItem = localStorage.getItem("activeItem");
  //   if (storedActiveItem) {
  //     setActiveItem(storedActiveItem);
  //   }
  // }, []);

  // // Save the active item to localStorage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem("activeItem", activeItem);
  // }, [activeItem]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    // Dispatch an action to check authentication on component mount
    dispatch(checkAuthentication()); // Replace with your actual checkAuthentication action
  }, [dispatch]);

  console.log("eske li otantifye :", isAuthenticated);

 

  return (
    <div >
      <div className="flex h-screen overflow-hidden">
        <div className="hidden sm:block">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
            <Link to="/" onClick={() => handleItemClick("Home")}>
              <SidebarItem
                icon={<Home size={20} />}
                text={"Home"}
                active={activeItem === "Home"}
              />
            </Link>
            <Link to="/dashboard" onClick={() => handleItemClick("dashboard")}>
              <SidebarItem
                icon={<LayoutDashboard size={20} />}
                text={"Dashboard"}
                active={activeItem === "dashboard"}
              />
            </Link>
            <Link to="/konekte" onClick={() => handleItemClick("konekte")}>
              <SidebarItem
                icon={<ListMusic size={20} />}
                text={"konekte"}
                active={activeItem === "konekte"}
                onClick={() => handleItemClick("konekte")}
              />
            </Link>
            <SidebarItem icon={<Package size={20} />} text={"Artist"} alert />
            <SidebarItem icon={<Receipt size={20} />} text={"Composer"} />
            <hr className="my-3" />
            <SidebarItem icon={<Settings size={20} />} text={"Settings"} />
            <SidebarItem icon={<LifeBuoy size={20} />} text={"Help"} />
          </Sidebar>
        </div>
        {sidebarOpen && (
          <div className="lg:hidden">
            {/* Content to be displayed only when sidebar is open on small screens */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
              <Link to="/" onClick={() => handleItemClick("Home")}>
                <SidebarItem
                  icon={<Home size={20} />}
                  text={"Home"}
                  active={activeItem === "Home"}
                />
              </Link>
              <Link
                to="/dashboard"
                onClick={() => handleItemClick("dashboard")}
              >
                <SidebarItem
                  icon={<LayoutDashboard size={20} />}
                  text={"Dashboard"}
                  active={activeItem === "dashboard"}
                />
              </Link>
              <Link to="/konekte" onClick={() => handleItemClick("konekte")}>
                <SidebarItem
                  icon={<ListMusic size={20} />}
                  text={"konekte"}
                  active={activeItem === "konekte"}
                  onClick={() => handleItemClick("konekte")}
                />
              </Link>
              <SidebarItem
                icon={<ListMusic size={20} />}
                text={"Playlist"}
                active={activeItem === "Playlist"}
                onClick={() => handleItemClick("Playlist")}
              />
              <SidebarItem icon={<Package size={20} />} text={"Artist"} alert />
              <SidebarItem icon={<Receipt size={20} />} text={"Composer"} />
              <hr className="my-3" />
              <SidebarItem icon={<Settings size={20} />} text={"Settings"} />
              <SidebarItem icon={<LifeBuoy size={20} />} text={"Help"} />
            </Sidebar>
          </div>
        )}

        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden mb-8"
        onClick={handleClick}
        >
          
          {showHeader && <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />}
         
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <Outlet />
            </div>
          </main>
        
        </div>
       
      </div>
      <div className="fixed bottom-0 w-full" 
      >
        <Player />
      </div>
    </div>
  );
};

export default Layout;
