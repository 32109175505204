export const artists = [
    {
      id: 1,
      name: 'Travis Scott',
      role: 'Artist',
      imageSrc: 'https://static01.nyt.com/images/2024/06/20/multimedia/20scott-arrest-fhvm/20scott-arrest-fhvm-videoSixteenByNine3000.jpg'
    },
    {
      id: 2,
      name: 'Bebe Rexha',
      role: 'Artist',
      imageSrc: 'https://media.cnn.com/api/v1/images/stellar/prod/gettyimages-1986455727.jpg?c=16x9&q=h_833,w_1480,c_fill'
    },
    {
      id: 3,
      name: 'Chris Brown',
      role: 'Artist',
      imageSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-Aleb4yEl4Rvc1CBi9LoDsRw0iAWMwJorWQ&s'
    }
  ];
  