import { MoreVertical, ChevronLast, ChevronFirst, X } from "lucide-react";
import { useContext, createContext, useState, useEffect } from "react";
import { logos } from "../../assets";
import useScreenSize from "../../utils/screenSizeUtils";

const SidebarContext = createContext();

export default function Sidebar({ children, sidebarOpen, setSidebarOpen }) {
  const [expanded, setExpanded] = useState(true);
  const screenSize = useScreenSize();
  const isSmallScreen =  screenSize === "sm" || screenSize === "xs";
  // const isLargeScreen = screenSize ==="lg";
  const isMediumScreen = screenSize ==="md"


  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );



 

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  console.log(screenSize,"   laje ekran")

  return (
    <aside
    className={`
      h-screen z-999999 bg-slate-50 dark:bg-slate-900 transition-all duration-300 ease-linear
      ${sidebarOpen && (isSmallScreen || isMediumScreen) ? "absolute left-0 top-0" : "hidden"}
      lg:block lg:static
    `}
    >
      <nav className="h-full flex flex-col bg-slate-50 dark:bg-slate-900  shadow-sm">
        <div className="flex items-center justify-between gap-2 px-6">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="absolute top-8 right-4"
          >
            {/* <svg
              className="fill-current"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                fill=""
              />
            </svg> */}
             {(isSmallScreen || isMediumScreen ) && <X/>}
          </button>
        </div>
        <div className="p-4 pb-2 flex justify-between items-center">
        {/*  <img
            src="https://img.logoipsum.com/243.svg"
            className={`overflow-hidden transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
            alt=""
          />*/}
          <h2 className={`dark:text-slate-50 text-slate-800 text-title-xl ${!expanded && "hidden"}`}>Banm Music</h2>
          {!(isSmallScreen || isMediumScreen ) && (
            <button
              onClick={() => setExpanded((curr) => !curr)}
              className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button>
          )}
        </div>

        <SidebarContext.Provider value={{ expanded,sidebarOpen, setSidebarOpen }}>

          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>

        {/* <div className="border-t flex p-3">
          <img
            src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
            alt=""
            className="w-10 h-10 rounded-md"
          />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
          `}
          >
            <div className="leading-4">
              <h4 className="font-semibold dark:text-slate-50">John Doe</h4>
              <span className="text-xs text-gray-600">johndoe@gmail.com</span>
            </div>
            <MoreVertical size={20} />
          </div>
        </div> */}
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, active, alert }) {
  const { expanded, sidebarOpen, setSidebarOpen } = useContext(SidebarContext);
  return (
    <li
      className={`
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group
        ${
          active
            ? "dark:text-slate-50 text-slate-800 dark:bg-slate-800 bg-slate-300"
            : "dark:text-slate-50 text-slate-800 dark:hover:bg-slate-700 hover:bg-slate-200"
        }
    `}
    >
      {icon}
      <span
         onClick={() => setSidebarOpen(!sidebarOpen)}
        className={`overflow-hidden transition-all ${
          expanded ? "w-52 ml-3" : "w-0"
        }`}
        
      >
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
            expanded ? "" : "top-2"
          }`}
        />
      )}

      {!expanded && (
        <div
          className={`
          absolute left-full rounded-md px-2 py-1 ml-6
          bg-indigo-100 text-indigo-slate-50 text-sm
          invisible -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-50 group-hover:translate-x-0
      `}
        >
          {text}
        </div>
      )}
    </li>
  );
}
