import { Routes, Route } from 'react-router-dom';
import { Layout, Profile, SignIn, SignUp, Player, Playlist,UploadMusic} from './components';
import { Homel, Home, Dashboard } from './pages';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

function App() {
  return (
    <div className='dark:bg-slate-900'>
    {/* ScrollToTop will ensure that every route starts at the top */}
    <Routes>
      <Route path='/' element={<Layout/>}>
        <Route path='' element={<Homel/>} />
        <Route path='profile' element={<Profile/>} />
        <Route path='dashboard' element={<Dashboard/>} />
        <Route path='konekte' element={<SignIn/>} />
        <Route path='enskri' element={<SignUp/>} />
        <Route path='player' element={<Player/>} />
        <Route path=':type/:id' element={<Playlist/>} />
      </Route>
    </Routes>
  </div>
  );
}

export default App;
