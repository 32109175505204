import axios from 'axios';

const API_URL = 'http://bmx.banm-mizik.com'; // Update your backend API URL
const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

// Intercept each request and include CSRF token
// api.interceptors.request.use(config => {
//     const csrfCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('csrftoken='));
//     if (csrfCookie) {
//         const csrfToken = csrfCookie.split('=')[1];
//         config.headers['X-CSRFToken'] = csrfToken;
//     } else {
//         console.error('CSRF token not found in cookies.');
//     }
//     return config;
// });

export default api