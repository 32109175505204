import React from "react";

const Artist = ({name, role, imageSrc}) => {
  return (
    <div className="max-w-sm mx-auto flex-shrink-0 dark:bg-slate-900 dark:hover:bg-[#24303F] rounded-lg shadow-md overflow-hidden">
      <div className="p-6 flex flex-col items-center">
        <img className="w-[200px] h-[200px]  rounded-[50%] object-cover" src={imageSrc} alt={`${name} profile`} />
        <div className="mt-4 text-center">
          <h2 className="text-lg font-semibold dark:text-slate-50">{name}</h2>
          <p className="text-sm text-gray-600">{role}</p>
        </div>
      </div>
    </div>
  );
};

export default Artist;
